var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3" },
    [
      !_vm.isSubmitted && !_vm.isCancelled
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "danger", icon: "delete" },
                      on: { click: _vm.deleteRow }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12, align: "end" } },
                [
                  _c(
                    "a-input-search",
                    {
                      staticStyle: { width: "75%" },
                      attrs: {
                        placeholder: _vm.$t("lbl_batch_number"),
                        "allow-clear": ""
                      },
                      on: { search: _vm.handleSearchBN },
                      model: {
                        value: _vm.vmBatchNumber,
                        callback: function($$v) {
                          _vm.vmBatchNumber = $$v
                        },
                        expression: "vmBatchNumber"
                      }
                    },
                    [
                      _c("a-button", {
                        attrs: {
                          slot: "enterButton",
                          type: "primary",
                          icon: "search"
                        },
                        slot: "enterButton"
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtSource,
                    scroll: { x: "calc(1300px + 50%)", y: 520 },
                    loading: _vm.loading.table,
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onSelectChange
                    },
                    pagination: {
                      showTotal: function(total) {
                        return _vm.$t("lbl_total_items", { total: total })
                      }
                    }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    { key: "no", attrs: { width: "65px", "data-index": "no" } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_number_short")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "batchNumber",
                      attrs: { "data-index": "batchNumber" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(record.batchNumber || "-"))
                              ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productCode",
                      attrs: { "data-index": "productCode" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productName",
                      attrs: { "data-index": "productName" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "qtyTotal",
                      attrs: { "data-index": "qtyTotal", width: 150 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toDecimalQty")(record.qtyTotal)
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty_total")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "locationName",
                      attrs: { "data-index": "locationName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(_vm._s(record.locationName || "-"))
                              ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_location")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    { key: "uom", attrs: { "data-index": "uom", width: 100 } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_uom")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "physicalQty",
                      attrs: { "data-index": "physicalQty" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: _vm.hasPrivApprovalStockOpname
                                    ? _vm.isSubmitted
                                      ? true
                                      : _vm.isCancelled
                                    : !_vm.isSubmitted || !_vm.isCancelled,
                                  placeholder: _vm.$t("lbl_type_here"),
                                  formatter: _vm.formatterNumber,
                                  parser: _vm.reverseFormatNumber,
                                  precision: _vm.DECIMAL_PLACES_QTY,
                                  min: 0
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.oninputQtyPhysical(record)
                                  }
                                },
                                model: {
                                  value: record.physicalQty,
                                  callback: function($$v) {
                                    _vm.$set(record, "physicalQty", _vm._n($$v))
                                  },
                                  expression: "record.physicalQty"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty_physical")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "qtyDifference",
                      attrs: { "data-index": "qtyDifference" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("toDecimalQty")(record.qtyDifference)
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty_difference")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "qualityControl",
                      attrs: { "data-index": "qualityControl.condition" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("CSelectCondition", {
                                attrs: {
                                  disabled: _vm.isSubmitted || _vm.isCancelled
                                },
                                on: { "on-select": _vm.onselectCondition },
                                model: {
                                  value: record.qualityControl.condition,
                                  callback: function($$v) {
                                    _vm.$set(
                                      record.qualityControl,
                                      "condition",
                                      $$v
                                    )
                                  },
                                  expression: "record.qualityControl.condition"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_condition")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "batchId",
                      attrs: { "data-index": "batchId" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              record.batchId && record.batchNumber
                                ? _c("QrCode", {
                                    attrs: {
                                      value: record.batchNumber,
                                      quality: 1,
                                      scale: 2,
                                      size: 80,
                                      "white-margin": false
                                    }
                                  })
                                : _c("span", { staticClass: "ant-form-text" }, [
                                    _vm._v(_vm._s(_vm.$t("lbl_not_available")))
                                  ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qrcode")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            [
              _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("lbl_total_found") + ": " + _vm.dtSource.length
                    ) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }