













































































































































































import { PRICE_TYPE, RESPONSE_STOCK_TYPE, STATE_STOCK_ADJUSTMENT } from "@enum/stock-adjustment.enum";
import { ResponseStockAdjustment } from "@interface/stock-adjustment.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import Vue from "vue";
import { inventoryLineBatchService } from "@service/inventory-line-batch.service";
import { ResponseInventoryLineBatch, ResponseListInventoryLineBatch } from "@interface/inventory-line-batch.interface";
import { Mode } from "@/models/enums/global.enum";
import { createNamespacedHelpers } from "vuex";
import { IAuthorities } from "@/models/interface-v2/auth.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { DECIMAL_PLACES_QTY, DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { DifferenceQtyCreateLine } from "@/models/interface-v2/difference-qty.interface";
import { formatterNumber, reverseFormatNumber } from "@/validator/globalvalidator";
import { Decimal } from "decimal.js-light";

const { mapGetters } = createNamespacedHelpers("authStore");

export interface RowQtyDiff extends DifferenceQtyCreateLine {
  no: number
  key: number
  batchNumber: string
  productCode: string
  productName: string
  qtyTotal: number
  uom: string
  qtyDifference: number
  locationName: string
}

export default Vue.extend({
  name: "TableQtyDifference",
  mixins: [
    MNotificationVue,
  ],
  props: {
    detailReports: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      DECIMAL_PLACES_QTY,
      selectedRowKeys: [] as number[],
      loading: {
        product: false,
        generate: false,
        qc: false,
        table: false,
      },
      paramProduct: {
        limit: DEFAULT_PAGE_SIZE,
        page: 0,
        sorts: "createdDate:desc",
        search: "",
      } as RequestQueryParamsModel,
      dtSource: [] as RowQtyDiff[],
      optUom: [] as {key: string, value: string}[],
      optQC: [] as { key: string, value: string }[],
      vmBatchNumber: "",
      flagDetailLoaded: false,

    };
  },
  computed: {
    ...mapGetters({
      userPrivileges: "GET_USER_PRIVILEGES"
    }),
    isSubmitted(): boolean {
      return this.detailReports.state === STATE_STOCK_ADJUSTMENT.SUBMITTED;
    },
    isCancelled(): boolean {
      return this.detailReports.state === STATE_STOCK_ADJUSTMENT.CANCELLED;
    },
    isDraft(): boolean {
      return this.detailReports.state === STATE_STOCK_ADJUSTMENT.DRAFT;
    },
    isModeView(): boolean {
      return this.$route.meta.mode === Mode.VIEW;
    },
    hasPrivApprovalStockOpname(): boolean {
      return this.userPrivileges.find((x: IAuthorities) => x.key === "approval-stock-opname");
    },
  },
  watch: {
    detailReports: {
      immediate: true,
      handler: "fillTable"
    },
  },
  deactivated() {
    if (!this.isSubmitted && !this.isCancelled && !this.isDraft) this.dtSource = [];
  },
  methods: {
    formatterNumber,
    reverseFormatNumber,
    getListInventoryLine(param: RequestQueryParamsModel): Promise<ResponseListInventoryLineBatch> {
      return inventoryLineBatchService.getListInventoryLineBatch(param);
    },
    async handleSearchBN(): Promise<void> {
      if (!this.vmBatchNumber) return;

      const hasDuplicate = this.dtSource.find(row => row.batchNumber === this.vmBatchNumber);
      if (hasDuplicate) {
        this.showNotifWarning("notif_duplicate_item", {data: hasDuplicate.batchNumber});
        return;
      }

      const findByBnAndAvailableGreaterThanZero = `batch.batchNumber~${this.vmBatchNumber}_AND_available>0`;
      this.paramProduct.search = findByBnAndAvailableGreaterThanZero;
      this.fillProduct();
    },
    onSelectChange(selectedRowKeys: number[]) {
      this.selectedRowKeys = selectedRowKeys;
    },
    deleteRow(): void {
      const { dtSource } = this;
      this.dtSource = dtSource.filter((row) => {
        return !this.selectedRowKeys.includes(row.key);
      });
      this.dtSource.forEach((data, index) => data.key = index);
      this.dtSource = this.dtSource.slice();
      this.emitDataUpdate();
      this.selectedRowKeys = [];
      this.setColNumber();
    },
    showNotifBnIsUsed(batchNumber: string): void {
      this.showNotifWarning("notif_warning_bn_already_used", {bn: batchNumber});
    },
    async fillProduct(): Promise<void> {
      try {
        this.loading.product = true;
        this.loading.table = true;
        const res = await this.getListInventoryLine(this.paramProduct);
        if (res.data.length > 0) {
          this.vmBatchNumber = "";
          this.showProductsLocation(res.data);
        } else {
          this.showNotifBnIsUsed(this.vmBatchNumber);
        }
      } catch (error) {
        this.showNotifError("notif_process_fail");
      } finally {
        this.loading.product = false;
        this.loading.table = false;
      }
    },
    showProductsLocation(data: ResponseInventoryLineBatch[]): void {
      const newRow: RowQtyDiff[] = data.map((x) => {
        return {
          key: this.dtSource.length + 1,
          batchId: x.batchId,
          id: "",
          locationId: x.warehouseLocationId,
          locationName: x.warehouseLocationName,
          physicalQty: 0,
          priceType: PRICE_TYPE.LATEST_PRICE,
          productId: x.product.id,
          qty: x.available,
          qualityControl: { condition: "" },
          uomId: x.uom.id,
          uom: x.uom.unit,
          batchNumber: x.batchNumber,
          productCode: x.product.code,
          productName: x.product.name,
          qtyTotal: x.onHand,
          qtyDifference: 0,
          no: this.dtSource.length,
        };
      });
      const copy = [...this.dtSource];
      this.dtSource = [...newRow, ...copy, ];
      this.setColNumber();
      this.emitDataUpdate();
    },
    setColNumber(): void {
      this.dtSource.forEach((x, i) => {
        x.no = i + 1;
        x.key = i;
      });
    },
    oninputQtyPhysical(record: RowQtyDiff): void {
      const { no } = record;
      this.dtSource[no - 1].qtyDifference = new Decimal(record.physicalQty || 0).minus(record.qtyTotal || 0).toNumber();
      this.emitDataUpdate();
    },
    emitDataUpdate(): void {
      this.$emit("onDataUpdate", { name: "qtyDifference", data: this.dtSource, deletedRow: (this.detailReports?.id ? this.selectedRowKeys : []) });
    },
    fillTable(): void {
      this.dtSource = [];
      const detail: ResponseStockAdjustment = {...this.detailReports};
      const { dtSource } = this;
      if (
        detail.type === RESPONSE_STOCK_TYPE.DIFFERENCE_QTY &&
        detail.stockAdjustmentLines &&
        detail.stockAdjustmentLines.length
      ) {
        detail.stockAdjustmentLines.forEach((item, i) => {
          dtSource.push({
            key: i,
            productCode: item.product.code,
            productName: item.product.name,
            qtyTotal: item.qty,
            uom: item.uom.unit,
            uomId: item.uom.id,
            physicalQty: item.physicalQty,
            qtyDifference: item.differenceQty,
            id: item.id,
            productId: item.product.id,
            qualityControl: item.qualityControl,
            batchId: item.batchNumberId,
            batchNumber: item.batchNumber,
            priceType: PRICE_TYPE.LATEST_PRICE,
            locationId: item.locationId,
            locationName: item.locationName,
            qty: item.qty,
            no: i + 1,
          });
        });
        this.dtSource = dtSource;
        this.flagDetailLoaded = true;
        this.emitDataUpdate();
      }
    },
    onselectCondition(): void {
      this.emitDataUpdate();
    },
  }
});
